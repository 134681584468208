<template>
  <section>
    <footer class="footer p-10 bg-base-200 text-base-content">
      <nav>
        <header class="footer-title">Services</header>
        <a class="link link-hover" href="https://accounts.solufit.net"
          >Accounts</a
        >
        <a class="link link-hover" href="https://wordbox.solufit.net"
          >Wordbox</a
        >
        <a class="link link-hover" href="https://tools.solufit.net">Tools</a>
      </nav>
      <nav>
        <header class="footer-title">Organization</header>
        <a class="link link-hover" href="https://about.solufit.net">About us</a>
        <a class="link link-hover" href="https://about.solufit.net/contact"
          >Contact</a
        >
      </nav>
      <nav>
        <header class="footer-title">Legal</header>
        <a class="link link-hover" href="https://about.solufit.net/terms"
          >Terms of use</a
        >
        <a class="link link-hover" href="https://about.solufit.net/privacy"
          >Privacy policy</a
        >
        <!--<a class="link link-hover">Cookie policy</a>-->
      </nav>
    </footer>
    <footer
      class="footer px-10 py-4 border-t bg-base-200 text-base-content border-base-300"
    >
      <aside class="items-center grid-flow-col">
        <img
          src="@/assets/images/logo-bg-clear.png"
          class="h-8 md:h-12"
          alt="Solufit"
        />
        <p class="ml-6">
          「Build future yourself」 <br />Close up YOU since 2021
        </p>
      </aside>
      <div class="grid-flow-col items-center">
        <span class="mx-1">🄫 2023 solufit.net </span>
      </div>
    </footer>
  </section>
</template>
