<template>
  <div class="h-screen">
    <section class="h-5/10">
      <div class="hidden md:flex items-center justify-center m-12">
        <div class="w-2/12">
          <button class="btn w-36" v-on:click="addtime(3600)">＋</button>
          <div class="text-10xl font-bold">
            {{
              Math.floor(time / 3600)
                .toString()
                .padStart(2, "0")
            }}
          </div>
          <button class="btn w-36" v-on:click="addtime(-3600)">ー</button>
        </div>
        <div class="text-10xl font-semibold w-1/12">:</div>
        <div class="w-2/12">
          <button class="btn w-36" v-on:click="addtime(60)">＋</button>
          <div class="text-10xl font-bold">
            {{
              Math.floor((time / 60) % 60)
                .toString()
                .padStart(2, "0")
            }}
          </div>
          <button class="btn w-36" v-on:click="addtime(-60)">ー</button>
        </div>
        <div class="text-10xl font-semibold w-1/12">:</div>
        <div class="w-2/12">
          <button class="btn w-36" v-on:click="addtime(1)">＋</button>
          <div class="text-10xl font-bold w-2/12">
            {{
              Math.floor(time % 60)
                .toString()
                .padStart(2, "0")
            }}
          </div>
          <button class="btn w-36" v-on:click="addtime(-1)">ー</button>
        </div>
      </div>
      <div class="flex md:hidden items-center justify-center my-12">
        <div class="w-2/12">
          <button class="btn w-12 my-2" v-on:click="addtime(3600)">＋</button>
          <div class="text-6xl font-bold">
            {{
              Math.floor(time / 3600)
                .toString()
                .padStart(2, "0")
            }}
          </div>
          <button class="btn w-12 my-2" v-on:click="addtime(-3600)">ー</button>
        </div>
        <div class="text-6xl font-semibold w-1/12">:</div>
        <div class="w-2/12">
          <button class="btn w-12 my-2" v-on:click="addtime(60)">＋</button>
          <div class="text-6xl font-bold">
            {{
              Math.floor((time / 60) % 60)
                .toString()
                .padStart(2, "0")
            }}
          </div>
          <button class="btn w-12 my-2" v-on:click="addtime(-3600)">ー</button>
        </div>
        <div class="text-6xl font-semibold w-1/12">:</div>
        <div class="w-2/12">
          <button class="btn w-12 my-2" v-on:click="addtime(1)">＋</button>
          <div class="text-6xl font-bold">
            {{
              Math.floor(time % 60)
                .toString()
                .padStart(2, "0")
            }}
          </div>
          <button class="btn w-12" v-on:click="addtime(-3600)">ー</button>
        </div>
      </div>
    </section>
    <section class="h-2/5">
      <div class="flex items-center justify-center w-full">
        <div class="p-3">
          <button
            class="btn btn-circle btn-primary text-xl w-36 h-24 md:w-56"
            v-on:click="startStop()"
            v-if="status == false"
          >
            スタート
          </button>
          <button
            class="btn btn-circle btn-primary text-xl w-36 h-24 md:w-56"
            v-on:click="startStop()"
            v-if="status == true"
          >
            ストップ
          </button>
        </div>
        <div class="p-3">
          <button
            class="btn btn-circle text-xl w-36 h-24 md:w-56"
            v-on:click="reset()"
          >
            リセット
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
const status = ref(false);
const time = ref(0);
const timerId = ref();
const dispId = ref();
const disp = ref(true);

const addtime = (delta: number) => {
  if (time.value + delta >= 0) {
    time.value = time.value + delta;
  } else {
    return;
  }
};

const startStop = () => {
  if (status.value == true) {
    clearInterval(timerId.value);
    status.value = false;
  } else {
    status.value = true;
    timerId.value = setInterval(() => {
      time.value--;
      if (time.value <= 0) {
        reset();
      }
    }, 1000);
  }
};

const reset = () => {
  clearInterval(dispId.value);
  disp.value = true;
  clearInterval(timerId.value);
  status.value = false;
  time.value = 0;
};
</script>
<style>
.text-10xl {
  font-size: 12vw;
  letter-spacing: 0.3rem;
}
.text-mini {
  font-size: 6vw;
  letter-spacing: 0.3rem;
}
</style>
