<template>
  <div class="flex items-center justify-center flex-wrap">
    <div class="w-full">
      <h1 class="text-xl md:text-2xl font-semibold">
        レーダーチャート メーカー
      </h1>
      <hr class="my-5" />
    </div>
    <div class="w-full h-full lg:w-1/2 input-fields">
      <div class="overflow-x-auto overflow-y-auto m-6">
        <table class="table table-fixed">
          <tbody>
            <!-- row 1 -->
            <tr
              v-for="(rdt, index) in radarData"
              v-bind:key="rdt.label"
              class="w-full"
            >
              <td class="flex items-center justify-center flex-wrap">
                <div class="w-full lg:w-1/2 py-1 lg:p-2">
                  <span class="text-xs">ラベル</span>
                  <input
                    type="text"
                    v-model.lazy="rdt.label"
                    class="input input-bordered w-full"
                  />
                </div>
                <div class="w-1/2 lg:w-1/4 pr-1 lg:p-2">
                  <span class="text-xs">値</span>
                  <input
                    type="number"
                    v-model.lazy="rdt.value"
                    class="input input-bordered w-full"
                  />
                </div>
                <div class="w-1/2 lg:w-1/4 pl-1 lg:p-2">
                  <span class="text-xs">最大値</span>
                  <input
                    type="number"
                    v-model.lazy="rdt.max"
                    class="input input-bordered w-full"
                  />
                </div>
              </td>
              <td class="w-2/5">
                <button
                  class="btn btn-accent w-16"
                  v-on:click="removeDataItem(index)"
                >
                  削除
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex items-center justify-center my-6">
        <div class="w-full lg:w-1/2 p-6 flex items-center justify-center">
          <button @click="addDataItem" class="btn btn-goast btn-primary w-full">
            新しい項目を追加
          </button>
        </div>
        <div class="w-full lg:w-1/2 p-6 flex items-center justify-center">
          <button
            @click="update_chart()"
            class="btn btn-goast btn-secondary w-full"
          >
            チャートを更新
          </button>
        </div>
      </div>
    </div>
    <div class="w-full p-6 lg:p-10 lg:w-1/2 chart-container">
      <Radar :data="normalizedData" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  scales,
} from "chart.js";
import { Radar } from "vue-chartjs";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
const radarData = ref([
  {
    label: "ラベル１",
    value: 25,
    max: 100,
  },
  {
    label: "ラベル２",
    value: 75,
    max: 100,
  },
  {
    label: "ラベル３",
    value: 65,
    max: 100,
  },
]);

const normalizedData = ref({
  labels: radarData.value.map((item) => item.label),
  datasets: [
    {
      label: "レーダーチャート",
      backgroundColor: "rgba(179,181,198,0.2)",
      borderColor: "rgba(179,181,198,1)",
      pointBackgroundColor: "rgba(179,181,198,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(179,181,198,1)",
      data: radarData.value.map((item) => (item.value / item.max) * 100),
    },
  ],
});

const addDataItem = () => {
  radarData.value.push({
    label: "新しいラベル",
    value: 50,
    max: 100,
  });
};
const removeDataItem = (index: any) => {
  radarData.value.splice(index, 1);
};

const update_chart = () => {
  normalizedData.value = {
    labels: radarData.value.map((item) => item.label),
    datasets: [
      {
        label: "レーダーチャート",
        backgroundColor: "rgba(179,181,198,0.2)",
        borderColor: "rgba(179,181,198,1)",
        pointBackgroundColor: "rgba(179,181,198,1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(179,181,198,1)",
        data: radarData.value.map((item) => (item.value / item.max) * 100),
      },
    ],
  };
};
</script>
