// src/store.ts

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    user: null,
    access_token: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    set_access_token(state, access_token) {
      state.access_token = access_token;
    },
    setLogout(state) {
      state.user = null;
      state.access_token = null;
    },
  },
  actions: {
    auth({ commit }, access_token) {
      // access_tokenのセット
      commit("set_access_token", access_token);
    },
    login({ commit }, user) {
      // ログイン処理が成功したと仮定し、ユーザー情報をコミットします
      commit("setUser", user);
    },
    logout({ commit }) {
      // ログアウト処理
      commit("setUser", null);
    },
  },
  plugins: [createPersistedState()],
});
