import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

import StopWatch from "@/components/tool/time/StopWatch.vue";
import TimerView from "@/components/tool/time/TimerView.vue";
import FocusTimer from "@/components/tool/time/FocusTimer.vue";
import StockTimer from "@/components/tool/time/StockTimer.vue";
import bs3Timer from "@/components/tool/time/3bsTimer.vue";
import bs2Timer from "@/components/tool/time/2bsTimer.vue";

import BarGraph from "@/components/tool/graph/BarGraph.vue";
import CircleGraph from "@/components/tool/graph/CircleGraph.vue";
import LineGraph from "@/components/tool/graph/LineGraph.vue";
import RadarChart from "@/components/tool/graph/ReaderChart.vue";

import ShortCutKey from "@/components/tool/knowledge/ShortCutKey.vue";
import PhysicsFomula from "@/components/tool/knowledge/PhysicsFomula.vue";
import ChemistryFomula from "@/components/tool/knowledge/ChemistryFomula.vue";

import MarkDown from "@/components/tool/editor/MarkDown.vue";

import SchoolCode from "@/components/temp/SchoolCode.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/time/stopwatch",
    name: "stopwatch",
    component: StopWatch,
  },
  {
    path: "/time/timer",
    name: "timer",
    component: TimerView,
  },
  {
    path: "/time/focus",
    name: "focustimer",
    component: FocusTimer,
  },
  {
    path: "/time/stock",
    name: "stock",
    component: StockTimer,
  },
  {
    path: "/time/2bs",
    name: "2bs",
    component: bs2Timer,
  },
  {
    path: "/time/3bs",
    name: "3bs",
    component: bs3Timer,
  },
  {
    path: "/graph/bar",
    name: "bargraph",
    component: BarGraph,
  },
  {
    path: "/graph/circle",
    name: "circlegraph",
    component: CircleGraph,
  },
  {
    path: "/graph/line",
    name: "linegraph",
    component: LineGraph,
  },
  {
    path: "/graph/radar",
    name: "radarchart",
    component: RadarChart,
  },
  {
    path: "/knowledge/shortcut",
    name: "shortcut",
    component: ShortCutKey,
  },
  {
    path: "/knowledge/physics",
    name: "physics",
    component: PhysicsFomula,
  },
  {
    path: "/knowledge/chemistry",
    name: "chemistry",
    component: ChemistryFomula,
  },
  {
    path: "/edit/markdown",
    name: "markdown",
    component: MarkDown,
  },
  {
    path: "/foroks/schoolcode",
    name: "schoolcode",
    component: SchoolCode,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
