<template>
  <div>
    <section class="flex justify-center items-center my-6">
      <div class="mx-2 lg:w-1/2 lg:mx-0">
        <section class="w-full my-6">
          <h1 class="font-bold font-3xl">大学コード検索データベース</h1>
        </section>
        <section class="w-full">
          <div class="flex w-full">
            <div class="px-2 lg:w-3/4">
              <input
                type="text"
                placeholder="学校名で検索"
                class="input input-bordered w-full max-w-lg"
                v-model.lazy="keyword"
              />
            </div>
            <div class="px-2 lg:w-1/4">
              <button class="btn btn-primary w-full" v-on="search()">
                検索
              </button>
            </div>
          </div>
        </section>
      </div>
    </section>
    <div class="overflow-x-auto mx-2">
      <table class="table">
        <!-- head -->
        <thead>
          <tr>
            <th>所在地</th>
            <th>区分</th>
            <th>学校名</th>
            <th>学部</th>
            <th>学科</th>
            <th></th>
            <th></th>
            <th></th>
            <th>コード</th>
          </tr>
        </thead>
        <tbody>
          <!-- row 1 -->
          <tr v-for="sc in result" v-bind:key="sc.name">
            <td>{{ sc.kenmei }}</td>
            <td>{{ sc.kubun }}</td>
            <td>{{ sc.name }}</td>
            <td>{{ sc.gakubu }}</td>
            <td>{{ sc.gakka }}</td>
            <td>{{ sc.f1 }}</td>
            <td>{{ sc.f2 }}</td>
            <td>{{ sc.f3 }}</td>
            <td>{{ sc.code }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup lang="ts">
import axios from "axios";
import { ref } from "vue";
const result = ref([]);
const keyword = ref();
const search = async () => {
  const key = keyword.value;
  axios
    .get(
      `https://api.sssapi.app/blqMMBOpwkfRloU89QPne?filter__name__contains=${key}`
    )
    .then((response) => {
      // 成功時の処理
      result.value = response.data;
    })
    .catch((error) => {
      // エラー時の処理
      console.error("エラーが発生しました:", error);
    });
};
</script>
