<template>
  <div class="home">
    <div class="hero h-24 bg-base-200">
      <div class="w-full">
        <div class="flex items-left w-full justify-center">
          <h1
            class="w-2/3 text-sm lg:text-xl font-bold section-baner flex flex-wrap"
          >
            <span class="w-full lg:w-auto">登録不要で使える</span>
            <span class="w-full lg:w-auto">オンラインツール集</span>
            <span class="w-full lg:w-auto">「Solufit Tools」</span>
          </h1>
          <a
            class="btn w-1/6"
            href="https://x.com/share?url=https://tools.solufit.net"
            rel="nofollow noopener"
            target="_blank"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="fill-current"
            >
              <path
                d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <section class="my-3">
      <div class="">
        <h1 class="font-semibold text-xl section-topic">Time</h1>
        <hr class="my-3" />
      </div>
      <section
        class="flex flex-wrap items-center lg:items-start justify-center lg:justify-start"
      >
        <a class="h-36 w-72 m-2 btn" href="/time/stopwatch">
          <h2 class="text-lg w-full normal-case">ストップウォッチ</h2>
          <p class="disc">シンプルなストップウォッチ</p>
        </a>
        <a class="h-36 w-72 m-2 btn" href="/time/timer">
          <h2 class="text-lg w-full normal-case">タイマー</h2>
          <p class="disc">シンプルなタイマー</p>
        </a>
        <a class="h-36 w-72 m-2 btn btn-disabled" href="/time/focus">
          <h2 class="text-lg w-full normal-case">Forcus Timer</h2>
          <p class="disc">ポモドーロ向けタイマー</p>
        </a>
        <a class="h-36 w-72 m-2 btn btn-disabled" href="/time/stock">
          <h2 class="text-lg w-full normal-case">Time Stock</h2>
          <p class="disc">２つのタイマーでの時間管理</p>
        </a>
        <a class="h-36 w-72 m-2 btn" href="/time/2bs">
          <h2 class="text-lg w-full normal-case">2進法タイマー</h2>
          <p class="disc">2進法で極める時間管理</p>
        </a>
        <a class="h-36 w-72 m-2 btn" href="/time/3bs">
          <h2 class="text-lg w-full normal-case">3進法タイマー</h2>
          <p class="disc">3進法から始める時間管理</p>
        </a>
      </section>
    </section>
    <section class="my-3">
      <div class="">
        <h1 class="font-semibold text-xl section-topic">Graph</h1>
        <hr class="my-3" />
      </div>
      <section
        class="flex flex-wrap items-center lg:items-start justify-center lg:justify-start"
      >
        <a class="h-36 w-72 m-2 btn btn-disabled" href="/graph/circle">
          <h2 class="text-lg w-full">円グラフ</h2>
          <p class="disc">複数のフィールドから円グラフを作成</p>
        </a>
        <a class="h-36 w-72 m-2 btn btn-disabled" href="/graph/bar">
          <h2 class="text-lg w-full">棒グラフ</h2>
          <p class="disc">複数のフィールドから棒グラフを作成</p>
        </a>
        <a class="h-36 w-72 m-2 btn btn-disabled" href="/graph/line">
          <h2 class="text-lg w-full">折れ線グラフ</h2>
          <p class="disc">複数のフィールドから折れ線グラフを作成</p>
        </a>
        <a class="h-36 w-72 m-2 btn" href="/graph/radar">
          <h2 class="text-lg w-full">レーダーチャート</h2>
          <p class="disc">複数のフィールドからレーダーチャートを作成</p>
        </a>
      </section>
    </section>
    <section class="my-3">
      <div class="">
        <h1 class="font-semibold text-xl section-topic">Knowledge</h1>
        <hr class="my-3" />
      </div>
      <section
        class="flex flex-wrap items-center lg:items-start justify-center lg:justify-start"
      >
        <a class="h-36 w-72 m-2 btn btn-disabled" href="/knowledge/shortcut">
          <h2 class="text-lg w-full normal-case">ShortCut Key</h2>
          <p class="disc"></p>
        </a>
        <a class="h-36 w-72 m-2 btn btn-disabled" href="/knowledge/physics">
          <h2 class="text-lg w-full normal-case">物理公式</h2>
          <p class="disc">高校物理の公式集</p>
        </a>
        <a class="h-36 w-72 m-2 btn btn-disabled" href="/knowledge/chemistry">
          <h2 class="text-lg w-full normal-case">化学公式</h2>
          <p class="disc">高校化学の公式集</p>
        </a>
      </section>
    </section>
    <section class="my-3">
      <div class="">
        <h1 class="font-semibold text-xl section-topic">Editor</h1>
        <hr class="my-3" />
      </div>
      <section
        class="flex flex-wrap items-center lg:items-start justify-center lg:justify-start"
      >
        <a class="h-36 w-72 m-2 btn btn-disabled" href="/editor/markdown">
          <h2 class="text-lg w-full normal-case">Markdown</h2>
          <p class="disc">リアルタイムプレビューのMarkdownエディター</p>
        </a>
      </section>
    </section>
  </div>
</template>
<style>
.section-topic {
  letter-spacing: 0.2rem;
}
.section-baner {
  letter-spacing: 0.15rem;
}
.disc {
  line-height: 1.5rem;
}
</style>
