<template>
  <div class="h-screen">
    <section>
      <h1 class="text-3xl font-bold my-1">2進法ストップウォッチ</h1>
      <p class="text-xs">from 共通テスト2024 数学</p>
    </section>
    <section class="h-2/5">
      <div class="hidden md:flex items-center justify-center m-12">
        <div class="text-10xl font-bold">
          {{ Math.floor(time).toString(2) }}
        </div>
      </div>
      <div class="flex md:hidden items-center justify-center my-12">
        <div class="text-6xl font-bold">
          {{ Math.floor(time).toString(2) }}
        </div>
      </div>
    </section>
    <section class="h-2/5">
      <div class="flex items-center justify-center w-full">
        <div class="p-3">
          <button
            class="btn btn-circle btn-primary text-xl w-36 h-24 md:w-56"
            v-on:click="startStop()"
            v-if="status == false"
          >
            スタート
          </button>
          <button
            class="btn btn-circle btn-primary text-xl w-36 h-24 md:w-56"
            v-on:click="startStop()"
            v-if="status == true"
          >
            ストップ
          </button>
        </div>
        <div class="p-3">
          <button
            class="btn btn-circle text-xl w-36 h-24 md:w-56"
            v-on:click="reset()"
          >
            リセット
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
const status = ref(false);
const time = ref(0);
const timerId = ref();

const startStop = () => {
  if (status.value == true) {
    clearInterval(timerId.value);
    status.value = false;
  } else {
    status.value = true;
    timerId.value = setInterval(() => {
      time.value++;
    }, 1000);
  }
};

const reset = () => {
  clearInterval(timerId.value);
  status.value = false;
  time.value = 0;
};
</script>
<style>
.text-10xl {
  font-size: 12vw;
  letter-spacing: 0.3rem;
}
.text-mini {
  font-size: 6vw;
  letter-spacing: 0.3rem;
}
</style>
