<template>
  <div class="h-screen">
    <section class="h-2/5">
      <div class="hidden md:flex items-center justify-center m-12">
        <div class="text-10xl font-bold w-2/12">
          {{
            Math.floor(time / 360000)
              .toString()
              .padStart(2, "0")
          }}
        </div>
        <div class="text-10xl font-semibold w-1/12">:</div>
        <div class="text-10xl font-bold w-2/12">
          {{
            Math.floor((time / 6000) % 60)
              .toString()
              .padStart(2, "0")
          }}
        </div>
        <div class="text-10xl font-semibold w-1/12">:</div>
        <div class="text-10xl font-bold w-2/12">
          {{
            Math.floor((time / 100) % 60)
              .toString()
              .padStart(2, "0")
          }}
        </div>
        <div class="ml-6 text-mini w-2/12">
          {{
            Math.round(time % 100)
              .toString()
              .padStart(2, "0")
          }}
        </div>
      </div>
      <div class="flex md:hidden items-center justify-center my-12">
        <div class="text-6xl font-bold w-2/12">
          {{
            Math.floor(time / 360000)
              .toString()
              .padStart(2, "0")
          }}
        </div>
        <div class="text-6xl font-semibold w-1/12">:</div>
        <div class="text-6xl font-bold w-2/12">
          {{
            Math.floor((time / 6000) % 60)
              .toString()
              .padStart(2, "0")
          }}
        </div>
        <div class="text-6xl font-semibold w-1/12">:</div>
        <div class="text-6xl font-bold w-2/12">
          {{
            Math.floor((time / 100) % 60)
              .toString()
              .padStart(2, "0")
          }}
        </div>
        <div class="text-2xl w-2/12">
          {{
            Math.round(time % 100)
              .toString()
              .padStart(2, "0")
          }}
        </div>
      </div>
    </section>
    <section class="h-2/5">
      <div class="flex items-center justify-center w-full">
        <div class="p-3">
          <button
            class="btn btn-circle btn-primary text-xl w-36 h-24 md:w-56"
            v-on:click="startStop()"
            v-if="status == false"
          >
            スタート
          </button>
          <button
            class="btn btn-circle btn-primary text-xl w-36 h-24 md:w-56"
            v-on:click="startStop()"
            v-if="status == true"
          >
            ストップ
          </button>
        </div>
        <div class="p-3">
          <button
            class="btn btn-circle text-xl w-36 h-24 md:w-56"
            v-on:click="reset()"
          >
            リセット
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
const status = ref(false);
const time = ref(0);
const timerId = ref();

const startStop = () => {
  if (status.value == true) {
    clearInterval(timerId.value);
    status.value = false;
  } else {
    status.value = true;
    timerId.value = setInterval(() => {
      time.value++;
    }, 10);
  }
};

const reset = () => {
  clearInterval(timerId.value);
  status.value = false;
  time.value = 0;
};
</script>
<style>
.text-10xl {
  font-size: 12vw;
  letter-spacing: 0.3rem;
}
.text-mini {
  font-size: 6vw;
  letter-spacing: 0.3rem;
}
</style>
