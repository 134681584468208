import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";

import { createMetaManager } from "vue-meta";

createApp(App)
  .use(router)
  .use(store)
  .use(createMetaManager())
  .use(ToastPlugin)
  .mount("#app");
