<template>
  <section>
    <div class="navbar bg-base-100">
      <div class="navbar-start">
        <a
          class="btn btn-ghost normal-case text-lg md:text-2xl text-stone-600"
          href="/"
        >
          <img
            src="@/assets/images/logo-bg-clear.png"
            class="h-8 md:h-12"
            alt="Solufit"
          />
          <span class="service-name">Tools</span>
        </a>
      </div>
      <div class="navbar-end">
        <button class="btn" onclick="my_modal_2.showModal()">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 1024 1024"
            class="icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#0f2350"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M885.746 741.427H514.972l-105.37 105.395c-0.788 0.764-1.77 1.09-2.623 1.726-0.787 0.59-1.333 1.42-2.228 1.922-0.416 0.218-0.875 0.218-1.29 0.416-1.53 0.742-3.08 1.158-4.718 1.55-1.224 0.284-2.404 0.614-3.627 0.7-1.551 0.11-3.037-0.086-4.566-0.306-1.376-0.195-2.709-0.35-4.042-0.786-1.2-0.416-2.292-1.028-3.43-1.64-1.551-0.808-2.971-1.683-4.304-2.862-0.35-0.306-0.787-0.414-1.114-0.72-0.547-0.548-0.745-1.268-1.224-1.836-0.72-0.875-1.66-1.508-2.25-2.492L314.28 741.427H169.847c-49.419 0-89.487-40.07-89.487-89.485V226.875c0-49.42 40.07-89.487 89.487-89.487h715.9c49.42 0 89.486 40.069 89.486 89.487v425.067c0.001 49.417-40.067 89.485-89.487 89.485zM307.07 729.26c-1.222-2.054-1.77-4.284-2.25-6.511 0.612 3.451 1.9 6.555 3.89 9.284l-1.64-2.773z m4.895-26.938c-0.59 0.524-1.202 0.938-1.726 1.506 0.502-0.546 1.158-1.006 1.726-1.506z m-7.669 17.87c-0.087-1.18 0.153-2.338 0.264-3.518-0.088 0.832-0.482 1.53-0.482 2.382 0 0.393 0.197 0.742 0.218 1.136z m2.316-10.988c-0.307 0.61-0.656 1.158-0.917 1.812 0.24-0.635 0.61-1.203 0.917-1.812z m623.878-482.33c0-24.71-20.033-44.743-44.744-44.743H169.847c-24.71 0-44.744 20.034-44.744 44.743V651.94c0 24.71 20.035 44.743 44.744 44.743h156.602c-0.326 0-0.612 0.174-0.938 0.195 8.04-0.327 16.015 3.344 20.318 10.597l51.821 87.435 91.74-91.76c4.609-4.587 10.66-6.663 16.69-6.464h379.667c24.711 0 44.744-20.034 44.744-44.743V226.874zM317.01 698.934c0.896-0.416 1.813-0.612 2.73-0.896-0.916 0.285-1.856 0.48-2.73 0.896z m412.133-225.97c-24.71 0-44.744-20.032-44.744-44.743 0-24.71 20.032-44.744 44.744-44.744 24.71 0 44.743 20.034 44.743 44.744s-20.034 44.744-44.743 44.744z m-201.347 0c-24.71 0-44.744-20.032-44.744-44.743 0-24.71 20.035-44.744 44.744-44.744s44.744 20.034 44.744 44.744-20.036 44.744-44.744 44.744z m-201.347 0c-24.71 0-44.744-20.032-44.744-44.743 0-24.71 20.035-44.744 44.744-44.744s44.743 20.034 44.743 44.744-20.033 44.744-44.743 44.744z"
                fill="#0f2350"
              ></path>
            </g>
          </svg>
          <span class="hidden md:block">FeedBack</span>
        </button>
        <dialog id="my_modal_2" class="modal">
          <div class="modal-box">
            <h3 class="font-semibold text-lg my-4 service-name">
              FeedBackを送信
            </h3>
            <div class="form-control my-3 w-full max-w-xs">
              <label class="label">
                <span class="label-text"
                  >関連するカテゴリについて教えてください</span
                >
              </label>
              <select class="select select-bordered" v-model.lazy="ufb_topic">
                <option disabled selected>選択してください</option>
                <option>Tools全般</option>
                <option>トップページについて</option>
                <option>提供されているツールについて</option>
                <option>その他</option>
              </select>
            </div>
            <div class="form-control my-3">
              <label class="label">
                <span class="label-text">詳しく教えてください</span>
              </label>
              <textarea
                class="textarea textarea-bordered h-48"
                v-model.lazy="ufb_content"
              ></textarea>
            </div>
            <p class="border-b">
              返信が必要な内容については
              <a href="https://about.solufit.net/contact" class="font-bold"
                >こちら
              </a>
              からご連絡ください
            </p>
            <button class="btn btn-primary m-6 w-96" v-on:click="ufb_send()">
              送信する
            </button>
            <p>フィードバックをご提供いただきありがとうございます</p>
          </div>
          <form method="dialog" class="modal-backdrop">
            <button>close</button>
          </form>
        </dialog>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { ref } from "vue";
import axios from "axios";
const ufb_content = ref();
const ufb_topic = ref();
const ufb_send = async () => {
  const webhockData = {
    embeds: [
      {
        title: "フィードバックが届きました",
        discription: new Date().toLocaleString(),
        color: 0x8cd5ff,
        fields: [
          {
            name: "カテゴリ",
            value: ufb_topic.value,
            inline: false,
          },
          {
            name: "内容",
            value: ufb_content.value,
            inline: false,
          },
        ],
      },
    ],
  };
  // Discord WebhookにHTTP POSTリクエストを送信
  await axios.post(process.env.VUE_APP_UFB_ENDPOINT, webhockData);
  ufb_content.value = "";
  ufb_topic.value = "";
  window.location.reload();
};
</script>
<style>
.service-name {
  margin-top: 0.12rem;
}
</style>
